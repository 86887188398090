<script lang="ts" setup>
import { useAppStore } from '../stores/app.ts';
import { Head } from '@inertiajs/vue3';
import { useToastStore } from '../stores/toast.ts';

defineProps<{ title: string }>();

const app = useAppStore();
const toast = useToastStore();
</script>

<template>
  <Head :title="title" />
  <VApp>
    <VLayout>
      <VMain>
        <VImg
          src="/images/hero-background-yellow.svg"
          absolute
          alt="background"
          aspect-ratio="2.7/1"
          class="banner bg-primary"
          height="300"
          position="right"
          width="100%"
        />
        <VContainer class="fill-height">
          <VCard class="mx-auto" width="500px">
            <VImg
              :src="`/images/logo/${app.globalTheme === 'light' ? 'light.svg' : 'dark.png'}`"
              alt="logo"
              height="100"
            />
            <VCardTitle class="text-center">
              {{ app.product.name }}
            </VCardTitle>
            <slot />
          </VCard>
        </VContainer>
      </VMain>
    </VLayout>

    <VSnackbar
      v-model="toast.show"
      :color="toast.color"
      :text="toast.message"
      :timeout="4000"
      location="top"
    />
  </VApp>
</template>
